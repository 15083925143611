import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import styles from "./loginPage.module.css";
import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import apiClient from "../services/apiclient";
import AlertPopup from "../components/AlertPopup";
function ResetPasswordPage() {
  const captcha_site_key = process.env.REACT_APP_SITE_KEY;
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(true);
  const [validCode, setValidCode] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [captcha, setCaptcha] = useState("");

  const [resetSuccess, setResetSuccess] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const target_code = queryParams.get("code");
    setCode(target_code);
    apiClient
      .get(`/auth/email-code/check?code=${target_code}`)
      .then((response) => {
        setValidCode(true);
      })
      .catch((error) => {
        setValidCode(false);
      });
    setLoading(false);
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Clear previous errors
    setPasswordError("");
    setConfirmPasswordError("");

    // Validate password length
    if (password.length < 8) {
      setPasswordError("Wachtwoord moet minimum 8 karakters lang zijn.");
      return;
    }

    // Validate password match
    if (password !== confirmPassword) {
      setPasswordError("Wachtwoorden komen niet overeen.");
      setConfirmPasswordError("Wachtwoorden komen niet overeen.");
      return;
    }
    apiClient
      .post(`/auth/reset-password?code=${code}`, {
        password: password,
        captcha_response_token: captcha,
      })
      .then((response) => {
        setResetSuccess(true);
        setAlert({
          open: true,
          message: "Je wachtwoord is gewijzigd. Je kan nu inloggen.",
          severity: "success",
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Er is iets fout gegaan. Probeer het later opnieuw.",
          severity: "error",
        });
      });

    // Submit logic here
    console.log("Form submitted with:", { password, confirmPassword, captcha });
  };

  return (
    <PageContainer className={styles.background}>
      <Card variant="filled" sx={{ p: 2, width: 0.9, maxWidth: 400 }}>
        {!loading && (
          <CardContent>
            {validCode ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h2>Nieuw wachtwoord</h2>
                <p>Geef hieronder een nieuw wachtwoord in.</p>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      label="Wachtwoord"
                      placeholder="Wachtwoord"
                      value={password}
                      error={!!passwordError}
                      helperText={passwordError}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      fullWidth
                    />
                    <TextField
                      label="Bevestig Wachtwoord"
                      placeholder="Bevestig Wachtwoord"
                      type="password"
                      error={!!confirmPasswordError}
                      helperText={confirmPasswordError}
                      fullWidth
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <ReCAPTCHA
                      sitekey={captcha_site_key}
                      onChange={(value) => setCaptcha(value)}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!captcha || !password || !confirmPassword}
                      color="primary"
                      fullWidth
                    >
                      Wachtwoord opnieuw instellen
                    </Button>
                  </Box>
                </form>
              </Box>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                  "& a": {
                    color: "black",
                  },
                }}
              >
                <p>
                  Deze code is ongeldig of mogelijks reeds gebruikt. Vraag een
                  nieuwe code aan via{" "}
                  <span>
                    <Link to="/login">de loginpagina</Link>
                  </span>
                </p>
              </Box>
            )}
          </CardContent>
        )}
      </Card>
      <AlertPopup alert={alert} setAlert={setAlert} />
    </PageContainer>
  );
}

export default ResetPasswordPage;
