import { useEffect, useState } from "react";
import PageContainer from "../components/PageContainer";
import apiClient from "../services/apiclient";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
function ManageVolunteersPage() {
  const { eventURN } = useParams();
  const [volunteers, setVolunteers] = useState([]);
  const [filteredVolunteers, setFilteredVolunteers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [postNames, setPostNames] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [days, setDays] = useState([]);
  console.log(eventURN);

  useEffect(() => {
    apiClient
      .get(`/manage/${eventURN}/volunteers`)
      .then((response) => {
        setVolunteers(response.data);

        // Extract unique post names from nested shifts
        const uniquePostNames = Array.from(
          new Set(
            response.data.flatMap(
              (volunteer) => volunteer.shifts.map((shift) => shift.post_name) // Adjust 'post_name' to match your data structure
            )
          )
        );
        const uniqueDays = Array.from(
          new Set(
            response.data.flatMap((volunteer) =>
              volunteer.shifts.map((shift) =>
                dayjs(shift.start).format("DD-MM")
              )
            )
          )
        );
        console.log(uniqueDays);
        setPostNames(uniquePostNames);
        setDays(uniqueDays);
      })
      .catch((error) => {
        console.error("Error fetching volunteers:", error);
      });
  }, [eventURN]);

  useEffect(() => {
    console.log(selectedPosts);
    let filterSet = volunteers;

    if (searchValue && searchValue.trim() !== "") {
      filterSet = filterSet.filter((volunteer) => {
        const full_name = `${volunteer.first_name} ${volunteer.last_name}`;
        return full_name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    if (selectedPosts.length > 0) {
      filterSet = filterSet.filter((volunteer) => {
        return selectedPosts.some((post) =>
          volunteer.shifts.some((shift) => shift.post_name === post)
        );
      });
    }
    if (selectedDays.length > 0) {
      filterSet = filterSet.filter((volunteer) => {
        return selectedDays.some((day) =>
          volunteer.shifts.some(
            (shift) => dayjs(shift.start).format("DD-MM") === day
          )
        );
      });
    }
    filterSet = filterSet.sort((a, b) => {
      const nameA = `${a.last_name} ${a.first_name}`.toLowerCase();
      const nameB = `${b.last_name} ${b.first_name}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });

    setFilteredVolunteers(filterSet);
  }, [searchValue, volunteers, selectedPosts, selectedDays]);

  return (
    <PageContainer>
      <h2>Vrijwilligers</h2>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "2rem 0",
          gap: "2rem",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <TextField
            label="Zoeken"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <FormControl sx={{ minWidth: "10rem", marginRight: "1rem" }}>
            <InputLabel id="post-filter-label">Filter op post</InputLabel>
            <Select
              labelId="post-filter-label" // Associates the label with this Select
              multiple
              label="filter op post"
              value={selectedPosts} // Array of selected posts
              onChange={(e) => setSelectedPosts(e.target.value)} // Handle updates on selection change
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {postNames.map((postName, index) => (
                <MenuItem key={index} value={postName}>
                  <Checkbox checked={selectedPosts.includes(postName)} />
                  {postName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: "10rem" }}>
            <InputLabel id="day-filter-label">Filter op dag</InputLabel>
            <Select
              labelId="day-filter-label" // Associates the label with this Select
              multiple
              label="filter op dag"
              value={selectedDays} // Array of selected days
              onChange={(e) => setSelectedDays(e.target.value)} // Handle updates on selection change
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {days.map((day, index) => (
                <MenuItem key={index} value={day}>
                  <Checkbox checked={selectedDays.includes(day)} />
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "80%",
            display: "grid",
            gridAutoRows: "1fr",
            gap: ".3rem",
          }}
        >
          {filteredVolunteers.map((volunteer) => {
            const registered_days = Array.from(
              new Set(
                volunteer.shifts.map((shift) =>
                  dayjs(shift.start).format("DD-MM")
                )
              )
            );
            const registered_posts = Array.from(
              new Set(volunteer.shifts.map((shift) => shift.post_name))
            );

            return (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: ".3rem",
                  rowGap: ".2rem",
                  padding: "1rem",
                  border: "1px solid #ccc",
                  borderRadius: "1rem",
                  justifyContent: "start",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#F1F1F1FF",
                  },
                }}
                key={volunteer.id}
              >
                <p>
                  {volunteer.last_name} {volunteer.first_name}
                </p>
                <Box
                  sx={{
                    gridRow: 2,
                    gridColumn: "1/3",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  {registered_days.map((day) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "var(--green)",
                          color: "white",
                          minWidth: "3rem",
                          textAlign: "center",
                          padding: ".2rem 1em",
                          borderRadius: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        {day}
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    gridRow: 3,
                    gridColumn: "1/3",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  {registered_posts.map((post) => {
                    return (
                      <Box
                        sx={{
                          backgroundColor: "var(--orange)",
                          color: "white",
                          minWidth: "3rem",
                          textAlign: "center",
                          padding: ".2rem 1em",
                          borderRadius: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        {post}
                      </Box>
                    );
                  })}
                </Box>
                <Typography
                  sx={{
                    gridRow: 4,
                    gridColumn: "1/3",
                    color: "lightgray",
                    textAlign: "right",
                  }}
                  variant="p"
                >
                  {volunteer.uuid}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </PageContainer>
  );
}

export default ManageVolunteersPage;
