import { Snackbar, Alert, Fade } from "@mui/material";

function AlertPopup({ setAlert, alert }) {
  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={3000}
      onClose={() =>
        setAlert((prevAlert) => ({ ...prevAlert, open: false }))
      }
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert severity={alert.severity} variant="filled">
        {alert.message}
      </Alert>
    </Snackbar>
  );
}

export default AlertPopup;
