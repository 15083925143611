import { useParams, Link, useNavigate } from "react-router-dom";
import PageContainer from "../components/PageContainer";
import { useState, useEffect } from "react";
import apiClient from "../services/apiclient";
import InnerBox from "../components/InnerBox";
import {
  Snackbar,
  Alert,
  Typography,
  Fade,
  Box,
  LinearProgress,
  Button,
  Select,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormControl,
} from "@mui/material";
import InputMask from "react-input-mask";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { isSameDay } from "date-fns";
import transformKeys from "../utils/keyUtils";
import LoadingScreen from "../components/LoadingScreen";
import AddressForm from "../components/formComponents/AddressForm";
import AnimatedBox from "../components/AnimatedBox";
import ConfirmationScreen from "../components/ConfirmationScreen";
import AlertPopup from "../components/AlertPopup";
function VolunteerRegistrationPage() {
  const { eventURN } = useParams();
  const navigate = useNavigate();
  const [direction, setDirection] = useState();
  const [eventName, setEventName] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [code, setCode] = useState("");
  const [codes, setCodes] = useState([]);
  const [days, setDays] = useState([]);
  const [formProposedQuestions, setFormProposedQuestions] = useState([]);
  const [formCustomQuestions, setFormCustomQuestions] = useState([]);
  const [registered, setRegistered] = useState(false);
  const [mode, setMode] = useState("register");

  const [formProposedAnswers, setFormProposedAnswers] = useState({}); // stores the answers to the proposed questions (=/= proposed answers)
  const [formCustomAnswers, setFormCustomAnswers] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState({});

  const [posts, setPosts] = useState([]);
  const [section, setSection] = useState(1);
  const [eventNotFound, setEventNotFound] = useState();
  const [loading, setLoading] = useState(true);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [error, setError] = useState();
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const maxSections = 5;

  useEffect(() => {
    console.log("custom answers: ", formCustomAnswers);
  }, [formCustomAnswers]);

  useEffect(() => {
    console.log("proposed answers: ", formProposedAnswers);
  }, [formProposedAnswers]);
  useEffect(() => {
    console.log(selectedShifts);
  }, [selectedShifts]);

  useEffect(() => {
    console.log(mode);
  }, [mode]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.returnValue = "changes";
    };

    if (!registered) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    // Cleanup on unmount or dependency change
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [registered]);

  useEffect(() => {
    const fetchData = () => {
      let postData;
      apiClient
        .get(`/events/public/${eventURN}`)
        .then((response) => {
          setEventName(response.data.name);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setAlert({
              open: true,
              message:
                "Het event werd niet gevonden. Mogelijks staat dit evenement privé. Probeer een andere URL of neem contact op met de organisator.",
              severity: "error",
            });
            setEventNotFound(true);
          }
        });

      apiClient
        .get("/users/me")
        .then((response) => {
          setFirstName(response.data.first_name);
          setLastName(response.data.last_name);
          setEmail(response.data.email);
          setPhoneNumber(response.data.phone_number);
          setDateOfBirth(response.data.date_of_birth);
        })
        .catch(null);

      apiClient
        .get(`/events/public/${eventURN}/posts`)
        .then((response) => {
          setDays(response.data.days);
          postData = response.data.posts;
          setPosts(postData);
        })
        .catch();

      apiClient
        .get(`/events/public/${eventURN}/volunteer-data`)
        .then((response) => {
          setMode("update");
          setSelectedDays(response.data.selected_days);
          setFormProposedAnswers(
            transformKeys(response.data.form_answers.proposed)
          );
          setFormCustomAnswers(
            transformKeys(response.data.form_answers.custom)
          );

          response.data.shifts.forEach((post) => {
            post.shifts.forEach((shiftId) => {
              console.log(postData);
              const targetShift = postData
                .flatMap((post) => Object.values(post.shifts).flat()) // Flatten all shifts across all posts
                .find((shift) => shift.id === shiftId); // Find the shift with the matching ID
              if (targetShift) {
                console.log("Found shift:", targetShift);
                console.log(post.post_id);
                handleShiftClick(post.post_id, post.code, targetShift);
              } else {
                console.log("Shift not found for ID:", shiftId);
              }
            });
          });
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            console.log("No volunteer data found");
            setMode("register");
          }
        });

      apiClient.get(`/events/public/${eventURN}/form`).then((response) => {
        setFormProposedQuestions(response.data.proposed_questions);
        setFormCustomQuestions(response.data.custom_questions);
      });
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleNextSection = () => {
    setDirection("next");
    setError();
    if (section === 1) {
      setSection(2);
    } else if (section === 2) {
      let error = false;
      formProposedQuestions.forEach((question) => {
        if (question.required) {
          if (
            !formProposedAnswers[question.id] ||
            formProposedAnswers[question.id] === ""
          ) {
            handleFormError(
              "proposed",
              question.id,
              "vul een geldige waarde in"
            );
            console.log("error", question.label);
            error = true;
          }
          if (question.type === "address") {
            const target = formProposedAnswers[question.id];
            let errorMessage = {
              street: "",
              number: "",
              postalCode: "",
              city: "",
            };
            if (!target?.street) {
              errorMessage.street = "Vul een geldige waarde in";
              error = true;
            }
            if (!target?.number) {
              errorMessage.number = "Vul een geldige waarde in";
              error = true;
            }
            if (!target?.postalCode) {
              errorMessage.postalCode = "Vul een geldige waarde in";
              error = true;
            }
            if (!target?.city) {
              errorMessage.city = "Vul een geldige waarde in";
              error = true;
            }
            console.log("address errormessage:", errorMessage);
            handleFormError("proposed", question.id, errorMessage);
          }
          if (
            question.type === "multiple choice" &&
            !formProposedAnswers[question.id]
          ) {
            if (formProposedQuestions[question.id].multi_select) {
              console.log("multiselect Error");
              handleFormError(
                "proposed",
                question.id,
                "Selecteer ten minste één optie"
              );
            } else {
              console.log("multiselect error");
              handleFormError("proposed", question.id, "Selecteer een optie");
            }
            error = true;
          }
          if (
            question.type === "confirmation" &&
            (formProposedAnswers[question.id] || false) === false
          ) {
            handleFormError(
              "proposed",
              question.id,
              "deze vraag is verplicht om verder te gaan."
            );
          }
        }
      });
      formCustomQuestions.forEach((question) => {
        if (question.required) {
          if (
            !formCustomAnswers[question.id] ||
            formCustomAnswers[question.id] === ""
          ) {
            handleFormError("custom", question.id, "vul een geldige waarde in");
            console.log("error", question.label);
            error = true;
          }
          if (question.type === "address") {
            const target = formCustomAnswers[question.id];
            let errorMessage = {};
            if (!target.street)
              errorMessage.street = "Vul een geldige waarde in";
            error = true;

            if (!target?.number) {
              errorMessage.number = "Vul een geldige waarde in";
              error = true;
            }
            if (!target?.postalCode) {
              errorMessage.postalCode = "Vul een geldige waarde in";
              error = true;
            }
            if (!target?.city) {
              errorMessage.city = "Vul een geldige waarde in";
              error = true;
            }
            console.log("address errormessage:", errorMessage);
            handleFormError("custom", question.id, errorMessage);
          }
          if (
            question.type === "multiple choice" &&
            !formCustomAnswers[question.id]
          ) {
            if (question.multi_select) {
              handleFormError(
                "custom",
                question.id,
                "Selecteer ten minste één optie"
              );
            } else {
              console.log("multiselect error");
              handleFormError("custom", question.id, "Selecteer een optie");
            }
            error = true;
          }
          if (
            question.type === "confirmation" &&
            (formCustomAnswers[question.id] || false) === false
          ) {
            handleFormError(
              "custom",
              question.id,
              "deze vraag is verplicht om verder te gaan."
            );
          }
        }
      });
      if (!error) {
        setSection(3);
      } else {
        console.log("error");
      }
    } else if (section === 3) {
      if (selectedDays.length === 0) {
        setError("selecteer ten minste één dag");
      } else {
        selectedDays.sort();
        setSection(4);
      }
    } else if (section === 4) {
      if (Object.keys(selectedShifts).length === 0) {
        setError("selecteer ten minste één shift");
      } else {
        setSection(5);
      }
    }
  };

  const handlePreviousSection = () => {
    setDirection("prev");
    if (section > 1) {
      setSection(section - 1);
    }
  };

  const handleSubmit = () => {
    const formattedShifts = Object.entries(selectedShifts).map(
      ([post_id, data]) => ({
        post_id: post_id, // Include post_id
        shifts: data.shifts.map((shift) => shift.id), // Map each shift to just its ID
        code: data.code, // Include the code
      })
    );
    const transformAnswers = (answers, questions) => {
      return Object.entries(answers).reduce((acc, [questionId, value]) => {
        const question = questions.find((q) => q.id === parseInt(questionId));
        if (question && question.type === "address") {
          acc[questionId] = {
            street: value.street,
            number: value.number,
            postal_code: value.postalCode,
            city: value.city,
          };
        } else {
          acc[questionId] = value; // Leave other answers as they are
        }
        return acc;
      }, {});
    };
    const transformedProposedAnswers = transformAnswers(
      formProposedAnswers,
      formProposedQuestions
    );
    const transformedCustomAnswers = transformAnswers(
      formCustomAnswers,
      formCustomQuestions
    );
    const payload = {
      shifts: formattedShifts,
      form_answers: {
        proposed: transformedProposedAnswers,
        custom: transformedCustomAnswers,
      },
    };
    if (mode === "register") {
      apiClient
        // same endpoint for updates. Back-end detects when the volunteer is already registers and updates the data directly.
        .post(`/events/public/${eventURN}/register`, payload)
        .then((response) => {
          setRegistrationSuccess(true);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            setAlert({
              open: true,
              message: "User already registered for this event",
              severity: "error",
            });
          }
          console.error(error);
        });
    } else if (mode === "update") {
      apiClient
        .put(`/events/public/${eventURN}/update`, payload)
        .then((response) => {
          setRegistrationSuccess(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleFormChange = (mode, questionId, value) => {
    const isEmpty = (val) => {
      if (val === undefined || val === null) return true; // Handle undefined and null
      if (typeof val === "string") return val.trim() === ""; // Handle empty string
      if (Array.isArray(val)) return val.length === 0; // Handle empty array
      if (val instanceof Set) return val.size === 0; // Handle empty Set
      if (typeof val === "object") return Object.keys(val).length === 0; // Handle empty object
      return false; // Default for other types
    };
    switch (mode) {
      case "proposed": {
        handleFormError("proposed", questionId, "");
        setFormProposedAnswers((prevData) => {
          const updatedData = { ...prevData };
          if (isEmpty(value)) {
            delete updatedData[questionId]; // Remove the key if value is empty
          } else {
            updatedData[questionId] = value; // Update the key with the new value
          }
          return updatedData;
        });
        break;
      }
      case "custom": {
        handleFormError("custom", questionId, "");
        setFormCustomAnswers((prevData) => {
          const updatedData = { ...prevData };
          if (isEmpty(value)) {
            delete updatedData[questionId]; // Remove the key if value is empty
          } else {
            updatedData[questionId] = value; // Update the key with the new value
          }
          return updatedData;
        });
        break;
      }
      default:
        break;
    }
  };

  const handleFormError = (mode, questionId, errorMessage) => {
    switch (mode) {
      case "proposed": {
        setFormProposedQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question.id === questionId
              ? { ...question, error: errorMessage }
              : question
          )
        );
        break;
      }
      case "custom": {
        setFormCustomQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question.id === questionId
              ? { ...question, error: errorMessage }
              : question
          )
        );
        break;
      }
      // You can add more cases for different modes if needed
    }
  };
  // redeem code for posts
  const handleCodeRedeem = () => {
    if (codes.includes(code)) {
      setAlert({
        open: true,
        message: "code al geregistreerd",
        severity: "warning",
      });
      return;
    }

    apiClient
      .get(`/events/public/${eventURN}/posts?code=${code}`)
      .then((response) => {
        if (response.data.posts.length === 0) {
          setAlert({
            open: true,
            message: "code is ongeldig",
            severity: "error",
          });
          return;
        }

        // Use functional updates to ensure the latest state is captured
        const postsWithCode = response.data.posts.map((post) => ({
          ...post,
          code: code,
        }));
        setPosts((prev_posts) => {
          const updatedPosts = [...prev_posts, ...postsWithCode];
          setAlert({
            open: true,
            message: `code is toegevoegd. ${response.data.posts.length} post(en) zichtbaar gemaakt`,
            severity: "success",
          });
          return updatedPosts;
        });

        setCodes((prev_codes) => {
          const updatedCodes = [...prev_codes, code];
          return updatedCodes;
        });
      })
      .catch((error) => {
        // Handle errors from the API request
        setAlert({
          open: true,
          message: "Er is een fout opgetreden bij het ophalen van de gegevens",
          severity: "error",
        });
        console.error("API error:", error);
      });
  };

  const checkForOverlaps = () => {
    // Create a shallow copy of the posts array to avoid mutating the state directly
    setPosts(
      posts.map((post) => {
        return {
          ...post,
          shifts: Object.fromEntries(
            Object.entries(post.shifts).map(([date, shiftsArray]) => [
              date,
              shiftsArray.map((shift) => {
                let isOverlapping = false;

                Object.keys(selectedShifts).forEach((post_id) => {
                  const Sshifts = selectedShifts[post_id].shifts;

                  Sshifts.forEach((selectedShift) => {
                    if (
                      selectedShift.start < shift.end &&
                      selectedShift.end > shift.start &&
                      selectedShift.id !== shift.id
                    ) {
                      isOverlapping = true;
                    }
                  });
                });
                return {
                  ...shift,
                  overlap: isOverlapping,
                };
              }),
            ])
          ),
        };
      })
    );
  };

  const handleShiftClick = (post_id, post_code, shift) => {
    setSelectedShifts((shifts) => {
      // Create a shallow copy of the shifts state
      const newShifts = { ...shifts };

      // Initialize or update shifts for the given post_id
      const shiftArray = newShifts[post_id]?.shifts
        ? [...newShifts[post_id].shifts]
        : [];

      // Find if the shift is already in the array
      const shiftIndex = shiftArray.findIndex((s) => s.id === shift.id);

      if (shiftIndex > -1) {
        // If shift is selected, remove it
        shiftArray.splice(shiftIndex, 1);
      } else {
        // If shift is not selected, add it
        shiftArray.push(shift);
      }

      // If shiftArray has shifts, update the post_id with code and shifts
      if (shiftArray.length > 0) {
        newShifts[post_id] = {
          shifts: shiftArray,
          code: post_code,
        };
      } else {
        // If no shifts are left, remove post_id completely
        delete newShifts[post_id];
      }

      return newShifts; // Return updated state
    });
  };

  const cleanupSelectedShifts = (day) => {
    setSelectedShifts((prevShifts) => {
      const updatedShifts = Object.keys(prevShifts).reduce((acc, post_id) => {
        // Filter out the shifts for the specific day for each post_id
        const filteredShifts = prevShifts[post_id].filter((shift) => {
          // Return only shifts that are not on the specified day
          return !dayjs(shift.start).isSame(dayjs(day), "day");
        });

        // Only add to the accumulator if there are remaining shifts after filtering
        if (filteredShifts.length > 0) {
          acc[post_id] = filteredShifts;
        }

        return acc; // Return the accumulator with filtered shifts
      }, {}); // Start with an empty object as the accumulator

      return updatedShifts; // Return the updated shifts object
    });
  };

  const formatAddress = (address) => {
    return `${address.street} ${address.number}, ${address.postalCode} ${address.city}`;
  };

  useEffect(() => {
    checkForOverlaps();
  }, [selectedShifts]);

  useEffect(() => {
    if (registrationSuccess) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [registrationSuccess, navigate]);
  return (
    <PageContainer>
      <Box
        sx={{
          minWidth: "100%",
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {registrationSuccess && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ConfirmationScreen
              message={
                mode === "register"
                  ? `je bent geregistreerd voor ${eventName}!`
                  : `inschrijving succesvol bijgewerkt`
              }
            />
          </Box>
        )}
        {loading && <LoadingScreen />}
        {!loading && !eventNotFound && !registrationSuccess && !registered && (
          <Box
            sx={{
              // maxWidth: "40rem",
              margin: "0 2rem",
              display: "flex",
              textAlign: "center",

              textSpacingTrim: "space-first",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              "& h1": {
                lineHeight: "2rem",
              },
            }}
          >
            <h1>Registratie voor {eventName}</h1>
            {section === 1 && ( // personal information
              <AnimatedBox direction={direction}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    "& a": {
                      textDecoration: "none",
                      color: "var(--blue)",
                      "&:hover": {
                        color: "var(--dark-blue)",
                      },
                    },
                  }}
                >
                  <h2>persoonlijke informatie</h2>
                  <p>
                    Is deze informatie correct? Indien niet, kan je dit{" "}
                    <Link to="settings/personal-information">hier</Link>{" "}
                    aanpassen
                  </p>

                  <Box
                    sx={{
                      display: "grid",
                      width: "100%",
                      gridTemplateColumns: { md: "1fr 1fr" },
                      columnGap: "1rem",
                      justifyItems: "start",
                    }}
                  >
                    <p>
                      <strong>voornaam</strong>
                    </p>
                    <p>{firstName}</p>
                    <p>
                      <strong>achternaam</strong>
                    </p>
                    <p>{lastName}</p>
                    <p>
                      <strong>e-mailadres</strong>
                    </p>
                    <p>{email}</p>
                    <p>
                      <strong>telefoonnummer</strong>
                    </p>
                    <p>{phoneNumber}</p>
                    <p>
                      <strong>geboortedatum</strong>
                    </p>
                    <p>{dateOfBirth}</p>
                  </Box>
                </Box>
              </AnimatedBox>
            )}
            {section === 2 && ( // vragen organisator
              <AnimatedBox direction={direction}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <h2>vragen van de organisator</h2>
                  <Typography
                    variant="p"
                    sx={{
                      mb: "1rem",
                    }}
                  >
                    Voluntex vraagt nooit om wachtwoorden of pincodes. Alle
                    informatie hier ingevuld zal zichtbaar zijn voor de
                    organisator van het evenement.
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      width: "100%",
                      columnGap: "1rem",
                      justifyItems: "stretch",
                      rowGap: "1rem",
                      "& p": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {formProposedQuestions.map((question) => {
                      switch (question.type) {
                        case "date":
                          return (
                            <Box
                              key={`p-${question.label}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <DatePicker
                                sx={{}}
                                slotProps={{
                                  textField: {
                                    error: !!question.error,
                                    helperText: question.error,
                                  },
                                }}
                                defaultValue={
                                  formProposedAnswers[question.id]
                                    ? dayjs(formProposedAnswers[question.id])
                                    : null
                                }
                                onChange={(e) =>
                                  handleFormChange(
                                    "proposed",
                                    question.id,
                                    dayjs(e)
                                  )
                                }
                              />
                            </Box>
                          );
                        case "Nation Register Number":
                          return (
                            <Box
                              key={`p-${question.label}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <InputMask
                                id={"p-" + question.id}
                                mask="99.99.99-999.99"
                                defaultValue={formProposedAnswers[question.id]}
                                placeholder={question.label}
                                onBlur={(e) =>
                                  handleFormChange(
                                    "proposed",
                                    question.id,
                                    e.target.value
                                  )
                                } // Pass onBlur to InputMask directly
                              >
                                {(inputProps) => (
                                  <TextField
                                    helperText={question.error}
                                    error={!!question.error}
                                    {...inputProps} // Spread other props from InputMask to TextField, but don't pass onBlur here
                                  />
                                )}
                              </InputMask>
                            </Box>
                          );
                          break;
                        case "text":
                          return (
                            <Box
                              key={`p-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <TextField
                                fullWidth
                                placeholder={question.label}
                                error={!!question.error}
                                helperText={question.error}
                                variant="outlined"
                                defaultValue={formProposedAnswers[question.id]}
                                onBlur={(e) =>
                                  handleFormChange(
                                    "proposed",
                                    question.id,
                                    e.target.value
                                  )
                                }
                              />
                            </Box>
                          );
                        case "phone number":
                          return (
                            <Box
                              key={`p-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <TextField
                                fullWidth
                                placeholder={question.label}
                                error={!!question.error}
                                helperText={question.error}
                                variant="outlined"
                                defaultValue={formProposedAnswers[question.id]}
                                onBlur={(e) =>
                                  handleFormChange(
                                    "proposed",
                                    question.id,
                                    e.target.value
                                  )
                                }
                              />
                            </Box>
                          );
                        case "number":
                          return (
                            <Box
                              key={`p-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <TextField
                                key={`p-${question.id}`}
                                fullWidth
                                type="number"
                                placeholder={question.label}
                                variant="outlined"
                                error={!!question.error}
                                helperText={question.error}
                                defaultValue={formProposedAnswers[question.id]}
                                onBlur={(e) =>
                                  handleFormChange(
                                    "proposed",
                                    question.id,
                                    e.target.value
                                  )
                                }
                              />
                            </Box>
                          );
                        case "multiple choice":
                          return (
                            <Box
                              key={`c-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              {!question.multi_select ? (
                                <RadioGroup
                                  onChange={(e) =>
                                    handleFormChange(
                                      "proposed",
                                      question.id,
                                      e.target.value
                                    )
                                  }
                                >
                                  <FormHelperText>
                                    {question.error}
                                  </FormHelperText>
                                  {question.options.map((option) => (
                                    <FormControlLabel
                                      key={`p-${question.id}-${option.id}`}
                                      checked={
                                        formProposedAnswers[question.id] ==
                                        option.id
                                      }
                                      value={option.id}
                                      control={<Radio />}
                                      label={option.label}
                                    />
                                  ))}
                                </RadioGroup>
                              ) : (
                                <FormGroup>
                                  {question.options.map((option) => {
                                    // Ensure selection is properly initialized as a Set
                                    const selection =
                                      formProposedAnswers[question.id] ||
                                      new Set();

                                    return (
                                      <FormControlLabel
                                        key={`p-${question.id}-${option.id}`}
                                        checked={selection.has(option.id)} // Reflect the current state
                                        onChange={(e) => {
                                          // Create a new Set to maintain immutability
                                          const updatedSelection = new Set(
                                            selection
                                          );
                                          if (e.target.checked) {
                                            updatedSelection.add(option.id);
                                          } else {
                                            updatedSelection.delete(option.id);
                                          }
                                          // Call handleFormChange with updated selection
                                          handleFormChange(
                                            "proposed",
                                            question.id,
                                            updatedSelection
                                          );
                                        }}
                                        value={option.id}
                                        label={option.label}
                                        control={<Checkbox />}
                                      />
                                    );
                                  })}
                                </FormGroup>
                              )}
                            </Box>
                          );
                        case "address":
                          return (
                            <Box
                              key={`p-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <AddressForm
                                defaultAddress={
                                  formProposedAnswers[question.id]
                                }
                                addressError={
                                  question.error
                                    ? question.error
                                    : {
                                        street: "",
                                        number: "",
                                        city: "",
                                        postalCode: "",
                                      }
                                }
                                onChange={(e) =>
                                  handleFormChange("proposed", question.id, e)
                                }
                              />
                            </Box>
                          );
                        case "confirmation":
                          return (
                            <Box
                              key={`c-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>bevestiging</p>
                              <FormControlLabel
                                checked={
                                  formProposedAnswers[question.id] === true
                                }
                                onClick={(e) =>
                                  handleFormChange(
                                    "proposed",
                                    question.id,
                                    e.target.checked
                                  )
                                }
                                control={
                                  <Checkbox
                                    name="confirmation" // Optionally add a name or id
                                    color="primary" // Choose your desired color
                                  />
                                }
                                label={question.label} // Customize the label for the checkbox
                              />
                            </Box>
                          );
                      }
                    })}
                    {formCustomQuestions.map((question) => {
                      switch (question.type) {
                        case "date":
                          return (
                            <Box
                              key={`p-${question.label}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <DatePicker
                                sx={{}}
                                slotProps={{
                                  textField: {
                                    error: !!question.error,
                                    helperText: question.error,
                                  },
                                }}
                                defaultValue={
                                  formCustomAnswers[question.id]
                                    ? dayjs(formCustomAnswers[question.id])
                                    : null
                                }
                                onChange={(e) =>
                                  handleFormChange(
                                    "custom",
                                    question.id,
                                    dayjs(e)
                                  )
                                }
                              />
                            </Box>
                          );
                          break;
                        case "Nation Register Number":
                          return (
                            <Box
                              key={`p-${question.label}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <InputMask
                                id={"p-" + question.id}
                                mask="99.99.99-999.99"
                                defaultValue={formCustomAnswers[question.id]}
                                placeholder={question.label}
                                onBlur={(e) =>
                                  handleFormChange(
                                    "custom",
                                    question.id,
                                    e.target.value
                                  )
                                } // Pass onBlur to InputMask directly
                              >
                                {(inputProps) => (
                                  <TextField
                                    helperText={question.error}
                                    error={!!question.error}
                                    {...inputProps} // Spread other props from InputMask to TextField, but don't pass onBlur here
                                  />
                                )}
                              </InputMask>
                            </Box>
                          );
                          break;
                        case "text":
                          return (
                            <Box
                              key={`p-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <TextField
                                fullWidth
                                placeholder={question.label}
                                error={!!question.error}
                                helperText={question.error}
                                variant="outlined"
                                defaultValue={formCustomAnswers[question.id]}
                                onBlur={(e) =>
                                  handleFormChange(
                                    "custom",
                                    question.id,
                                    e.target.value
                                  )
                                }
                              />
                            </Box>
                          );
                          break;
                        case "phone number":
                          return (
                            <Box
                              key={`p-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              <TextField
                                fullWidth
                                placeholder={question.label}
                                error={!!question.error}
                                helperText={question.error}
                                variant="outlined"
                                defaultValue={formCustomAnswers[question.id]}
                                onBlur={(e) =>
                                  handleFormChange(
                                    "custom",
                                    question.id,
                                    e.target.value
                                  )
                                }
                              />
                            </Box>
                          );
                        case "number":
                          return (
                            <Box
                              key={`p-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <TextField
                                key={`p-${question.id}`}
                                fullWidth
                                type="number"
                                placeholder={question.label}
                                variant="outlined"
                                error={!!question.error}
                                helperText={question.error}
                                defaultValue={formCustomAnswers[question.id]}
                                onBlur={(e) =>
                                  handleFormChange(
                                    "custom",
                                    question.id,
                                    e.target.value
                                  )
                                }
                              />
                            </Box>
                          );
                          break;
                        case "multiple choice":
                          return (
                            <Box
                              key={`c-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>{question.label}</p>
                              {!question.multi_select ? (
                                <RadioGroup
                                  onChange={(e) =>
                                    handleFormChange(
                                      "custom",
                                      question.id,
                                      e.target.value
                                    )
                                  }
                                >
                                  {question.options.map((option) => (
                                    <FormControlLabel
                                      key={`p-${question.id}-${option.id}`}
                                      checked={
                                        formCustomAnswers[question.id] ==
                                        option.id
                                      }
                                      value={option.id}
                                      control={<Radio />}
                                      label={option.label}
                                    />
                                  ))}
                                  <FormHelperText></FormHelperText>
                                </RadioGroup>
                              ) : (
                                <FormControl error={!!question.error}>
                                  <FormGroup>
                                    {question.options.map((option) => {
                                      // Ensure selection is properly initialized as a Set
                                      const selection =
                                        formCustomAnswers[question.id] ||
                                        new Array();

                                      return (
                                        <FormControlLabel
                                          key={`p-${question.id}-${option.id}`}
                                          checked={selection.includes(
                                            option.id
                                          )} // Use `Array.includes`
                                          onChange={(e) => {
                                            let updatedSelection;
                                            if (e.target.checked) {
                                              // Add the value if it's not already in the array
                                              updatedSelection =
                                                selection.includes(option.id)
                                                  ? selection
                                                  : [...selection, option.id];
                                            } else {
                                              // Remove the value from the array
                                              updatedSelection =
                                                selection.filter(
                                                  (id) => id !== option.id
                                                );
                                            }
                                            // Call handleFormChange with updated selection
                                            handleFormChange(
                                              "custom",
                                              question.id,
                                              updatedSelection
                                            );
                                          }}
                                          value={option.id}
                                          label={option.label}
                                          control={<Checkbox />}
                                        />
                                      );
                                    })}
                                  </FormGroup>
                                  <FormHelperText>
                                    {question.error}
                                  </FormHelperText>
                                </FormControl>
                              )}
                            </Box>
                          );
                        case "confirmation":
                          return (
                            <Box
                              key={`c-${question.id}`}
                              sx={{
                                display: "grid",
                                justifyItems: "start",
                              }}
                            >
                              <p>bevestiging</p>
                              <FormControlLabel
                                checked={
                                  !!formCustomAnswers[question.id] || false
                                } // Ensure it's a boolean value
                                onChange={(e) =>
                                  handleFormChange(
                                    "custom",
                                    question.id,
                                    e.target.checked
                                  )
                                }
                                control={<Checkbox />}
                                label={question.label} // Customize the label for the checkbox
                              />
                              {question.error && (
                                <FormHelperText error>
                                  {question.error}
                                </FormHelperText> // Ensure the error message is displayed properly
                              )}
                            </Box>
                          );
                      }
                    })}
                  </Box>
                </Box>
              </AnimatedBox>
            )}

            {section === 3 && (
              <AnimatedBox
                direction={direction}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  "& a": {
                    textDecoration: "none",
                    color: "var(--blue)",
                    "&:hover": {
                      color: "var(--dark-blue)",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    textAlign: "center",
                    width: "80%",
                  }}
                >
                  <h2>selecteer dagen</h2>
                  <p>
                    selecteer de dagen dat je je vrijwillig ten dienste wilt
                    stellen
                  </p>
                  {selectedDays.length ? (
                    <p>
                      <strong>
                        {selectedDays.length}{" "}
                        {selectedDays.length > 1 ? "dagen" : "dag"} geselecteerd
                      </strong>
                    </p>
                  ) : null}
                  <Box
                    sx={{
                      display: "grid",
                      alignSelf: "start",
                    }}
                  >
                    {days.map((day, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr 2fr",
                            alignItems: "center",
                            justifyItems: "start",
                            columnGap: "2rem",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => {
                            setSelectedDays((prevDays) => {
                              if (!selectedDays.includes(day)) {
                                return [...prevDays, day];
                              } else {
                                cleanupSelectedShifts(day);
                                return prevDays.filter((d) => d !== day);
                              }
                            });
                          }}
                        >
                          <Checkbox
                            checked={selectedDays.includes(day)}
                            onClick={null}
                          />

                          <Typography variant="p" sx={{}}>
                            {dayjs(day).format("dddd")}
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              textAlign: "right",
                            }}
                          >
                            {dayjs(day).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </AnimatedBox>
            )}
            {section === 4 && (
              <AnimatedBox direction={direction}>
                <Box
                  sx={{
                    position: "relative",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <h2>Selecteer Shiften</h2>
                  <Box
                    sx={{
                      position: { md: "absolute" },
                      top: "0rem",
                      right: "0rem",
                      display: "grid",
                      gridTemplateColumns: "1.5fr 1fr",
                      margin: "auto",
                      gap: ".5rem",
                      alignItems: "stretch",
                      justifyItems: "stretch",
                      width: "30vw",
                      maxWidth: "15rem",
                      zIndex: "1001",
                    }}
                  >
                    <TextField
                      inputProps={{
                        maxLength: 6,
                      }}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="code"
                      sx={{
                        zIndex: "2000",
                      }}
                    ></TextField>
                    <Button
                      variant="contained"
                      sx={{}}
                      onClick={handleCodeRedeem}
                    >
                      valideren
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    {selectedDays.map((day) => {
                      const matchingPosts = posts.filter((post) =>
                        post.shifts.hasOwnProperty(day)
                      );
                      return (
                        <Box key={day}>
                          <Box
                            sx={{
                              zIndex: 1000,
                              width: "100%",
                              backgroundColor: "white",
                              position: "sticky",
                              padding: ".5rem 0 .5rem",
                              borderBottom: "1px solid lightgray",
                              top: "4rem",
                            }}
                          >
                            <h3>{dayjs(day).format("dddd DD/MM")}</h3>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",

                              alignItems: "stretch",
                              flexWrap: "wrap",
                              gap: "1rem",
                            }}
                          >
                            {matchingPosts.map((post) => {
                              const shifts = post.shifts[day] || [];
                              const matchingShifts = shifts.filter(
                                (shift) =>
                                  dayjs(shift.start).format("YYYY-MM-DD") ===
                                  day
                              );

                              return (
                                <InnerBox width="15rem">
                                  <Box
                                    sx={{
                                      height: "100%",
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "12rem",
                                      }}
                                    >
                                      {" "}
                                      {/* Consistent height for main content */}
                                      <h3>{post.name}</h3>
                                      <Typography
                                        variant="p"
                                        sx={{
                                          fontSize: ".9rem",
                                        }}
                                      >
                                        {post.description}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        paddingBottom: "1rem", // Space below shifts for consistency
                                        gap: ".3rem",
                                      }}
                                    >
                                      {matchingShifts.map((shift) => {
                                        let bgColor = "blue";
                                        if (shift.overlap) {
                                          bgColor = "gray";
                                        }
                                        if (
                                          selectedShifts[post.id]?.shifts?.some(
                                            (s) => s.id === shift.id
                                          )
                                        ) {
                                          bgColor = "green";
                                        }
                                        const differentDate =
                                          !dayjs(shift.start).isSame(
                                            dayjs(shift.end),
                                            "day"
                                          ) &&
                                          dayjs(shift.end).format("HH:mm") !==
                                            "00:00";

                                        return (
                                          <Box
                                            sx={{
                                              position: "relative",
                                              display: "grid",
                                              backgroundColor: `var(--${bgColor})`,
                                              borderRadius: "2rem",
                                              justifyItems: "stretch",
                                              alignItems: "center",
                                              color: "white",
                                              gridTemplateColumns:
                                                "1fr auto 1fr",
                                              cursor: shift.overlap
                                                ? "not-allowed"
                                                : "pointer",
                                              "& p": {
                                                margin: ".5rem",
                                              },
                                              "& span": {
                                                fontSize: ".7rem",
                                                position: "absolute",
                                                top: "4%",
                                              },
                                              flex: "1", // Stretch to fill remaining space
                                              minHeight: "3.7rem", // Ensure each shift box has a minimum height
                                            }}
                                            onClick={() => {
                                              if (!shift.overlap) {
                                                handleShiftClick(
                                                  post.id,
                                                  post.code,
                                                  shift
                                                );
                                              }
                                            }}
                                          >
                                            {differentDate && (
                                              <Typography
                                                variant="p"
                                                sx={{
                                                  position: "absolute",
                                                  top: "10%",
                                                  left: "17%",
                                                }}
                                              >
                                                {dayjs(shift.start).format(
                                                  "DD/MM"
                                                )}
                                              </Typography>
                                            )}
                                            <p>
                                              {dayjs(shift.start).format(
                                                "HH:mm"
                                              )}
                                            </p>
                                            <p>-</p>
                                            {differentDate && (
                                              <Typography
                                                variant="p"
                                                sx={{
                                                  right: "17%",
                                                }}
                                              >
                                                {dayjs(shift.end).format(
                                                  "DD/MM"
                                                )}
                                              </Typography>
                                            )}
                                            <p>
                                              {dayjs(shift.end).format("HH:mm")}
                                            </p>
                                          </Box>
                                        );
                                      })}
                                    </Box>
                                  </Box>
                                </InnerBox>
                              );
                            })}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </AnimatedBox>
            )}
            {section === 5 && (
              <AnimatedBox direction={direction}>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <h2>samenvatting</h2>
                  <InnerBox>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          gridColumn: "1/3",
                        }}
                      >
                        shiften
                      </Typography>
                      {selectedDays.map((day) => {
                        return (
                          <Box
                            sx={{
                              margin: "1rem 0",
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                fontWeight: "bold",
                                gridColumn: "1/3",
                                justifySelf: "start",
                              }}
                            >
                              {dayjs(day).format("dddd DD/MM")}
                            </Typography>

                            {Object.keys(selectedShifts).map((post_id) => {
                              const post = posts.find(
                                (post) => post.id === Number(post_id)
                              );
                              const matchingShifts = selectedShifts[
                                post_id
                              ].shifts.filter((shift) =>
                                dayjs(shift.start).isSame(day, "day")
                              );
                              if (matchingShifts.length === 0) return null;
                              return (
                                <Box
                                  sx={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 2fr",
                                    gap: "0.5rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      gridRow: `1/${
                                        selectedShifts[post_id].shifts.length +
                                        1
                                      }`,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="p"
                                      text
                                      sx={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {post.name}
                                    </Typography>
                                  </Box>
                                  {matchingShifts.map((shift) => {
                                    const isSameDate = dayjs(
                                      shift.start
                                    ).isSame(dayjs(shift.end), "day");
                                    return (
                                      <Box
                                        sx={{
                                          display: "grid",
                                          justifyItems: "center",
                                          alignItems: "center",
                                          gridTemplateColumns:
                                            "3fr 3fr 1fr 3fr 3fr",
                                          gap: ".5rem",
                                          "& p": {
                                            margin: 0,
                                          },
                                        }}
                                      >
                                        <p>
                                          {dayjs(shift.start).format("DD/MM")}
                                        </p>
                                        <p>
                                          {dayjs(shift.start).format("HH:mm")}
                                        </p>
                                        <p>-</p>
                                        <p>
                                          {dayjs(shift.end).format("DD/MM")}
                                        </p>
                                        <p>
                                          {dayjs(shift.end).format("HH:mm")}
                                        </p>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      })}
                    </Box>
                  </InnerBox>
                </Box>
              </AnimatedBox>
            )}
            <Typography
              variant="p"
              sx={{
                color: "var(--red)",
              }}
            >
              {error}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyItems: "center",
                alignItems: "stretch",
                gap: { xs: "0.2rem", md: ".5rem" },
                width: "80%",

                maxWidth: "32rem",
                margin: "auto",
              }}
            >
              {section !== 1 && (
                <Button
                  variant="contained"
                  onClick={handlePreviousSection}
                  fullWidth
                >
                  vorige
                </Button>
              )}

              {section < maxSections && (
                <Button
                  variant="contained"
                  onClick={handleNextSection}
                  fullWidth
                  sx={{ gridColumn: "2" }}
                >
                  volgende
                </Button>
              )}
              {section === maxSections && (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  fullWidth
                  sx={{
                    gridColumn: mode === "register" ? "2" : "2/5",
                    backgroundColor: "var(--green)",
                    "&:hover": { backgroundColor: "var(--green-hover)" },
                    "&:focus": { backgroundColor: "var(--green-hover)" },
                  }}
                >
                  {mode === "register" ? (
                    <span>inschrijven</span>
                  ) : (
                    <span>wijzigingen opslaan</span>
                  )}
                </Button>
              )}
            </Box>
            <LinearProgress
              variant="determinate"
              value={(section / maxSections) * 100}
              sx={{
                width: "80%",
                maxWidth: "32rem",
                marginTop: "2rem",
              }}
            />
            <p>
              {section} van {maxSections}
            </p>
          </Box>
        )}
        <AlertPopup alert={alert} setAlert={setAlert} />
      </Box>
    </PageContainer>
  );
}
export default VolunteerRegistrationPage;
