import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../services/apiclient";
import InnerBox from "../components/InnerBox";
import PageContainer from "../components/PageContainer";
import transformKeys from "../utils/keyUtils";
import { Box, Button, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import PeopleIcon from "@mui/icons-material/People";
import RoomIcon from "@mui/icons-material/Room";
import EventIcon from "@mui/icons-material/Event";
function DashboardPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [organizedEvents, setOrganizedEvents] = useState([]);
  const [managedEvents, setManagedEvents] = useState([]);
  const [eventRequested, setEventRequested] = useState(false);

  const [days, setDays] = useState([]);
  const [volunteers, setVolunteers] = useState([]);
  const [postNames, setPostNames] = useState([]);
  const iconButtonStyle = {
    borderRadius: "1em",
    width: "1.5em",
    height: "1.5em",
    backgroundColor: "lightgray",
    transition: ".5s",
    ":hover": {
      backgroundColor: "#2457FF",
      color: "white",
      transition: ".5s",
    },
  };

  function formatDate(isoDate) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(isoDate).toLocaleDateString("en-GB", options); // 'en-GB' is for dd/mm/yyyy format
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    apiClient
      .get("/users/me")
      .then((response) => {
        setUserData(response.data);
        setOrganizedEvents(response.data.organized_events);
        setManagedEvents(response.data.managed_events);
        setEventRequested(false);

        setVolunteers(response.data.registrations);

        // Extract unique post names from nested shifts
        const uniquePostNames = Array.from(
          new Set(
            response.data.flatMap(
              (volunteer) => volunteer.shifts.map((shift) => shift.post_name) // Adjust 'post_name' to match your data structure
            )
          )
        );

        const uniqueDays = Array.from(
          new Set(
            response.data.registrations.flatMap((volunteer) =>
              volunteer.shifts.map((shift) =>
                dayjs(shift.start).format("DD-MM")
              )
            )
          )
        );
        console.log(uniqueDays);
        setPostNames(uniquePostNames);
        setDays(uniqueDays);
      })
      .catch((error) => {
        console.error("Failed to fetch user data:", error);
      });
  }, []);

  return (
    <PageContainer>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "2rem",
          paddingTop: "5em",
          paddingBottom: "5em",
        }}
      >
        <h1>DASHBOARD</h1>
        {userData && (
          <p>
            <strong>Welkom terug, {userData.first_name}.</strong>
          </p>
        )}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <InnerBox width="100%" maxWidth="25em">
            <h4>mijn registraties</h4>

            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection : "column",
                display: "flex",
                gap: "1rem",
              }}
            >
              {volunteers.map((volunteer) => {
                const registered_days = Array.from(
                  new Set(
                    volunteer.shifts.map((shift) =>
                      dayjs(shift.start).format("DD-MM")
                    )
                  )
                );

                const registered_posts = Array.from(
                  new Set(volunteer.shifts.map((shift) => shift.post_name))
                );

                return (
                  <Box
                    sx={{
                      display: "grid",
                      width: "90%",
                      gridTemplateColumns: "1fr 1fr",
                      columnGap: ".3rem",
                      rowGap: ".2rem",
                      padding: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "#F1F1F1FF",
                      },
                    }}
                    key={volunteer.id}
                    onClick={() => navigate(`/events/${volunteer.event_urn}`)}
                  >
                    <Typography
                      fullWidth
                      variant="p"
                      sx={{
                        gridColumn: "1/3",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {volunteer.event_name}
                    </Typography>
                    <Box
                      sx={{
                        gridRow: 2,
                        gridColumn: "1/3",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      {registered_days.map((day) => {
                        return (
                          <Box
                            sx={{
                              backgroundColor: "var(--green)",
                              color: "white",
                              minWidth: "3rem",
                              textAlign: "center",
                              padding: ".2rem 1em",
                              borderRadius: "1rem",
                              cursor: "pointer",
                            }}
                          >
                            {day}
                          </Box>
                        );
                      })}
                    </Box>
                    <Box
                      sx={{
                        gridRow: 3,
                        gridColumn: "1/3",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      {registered_posts.map((post) => {
                        return (
                          <Box
                            sx={{
                              backgroundColor: "var(--orange)",
                              color: "white",
                              minWidth: "3rem",
                              textAlign: "center",
                              padding: ".2rem 1em",
                              borderRadius: "1rem",
                              cursor: "pointer",
                            }}
                          >
                            {post}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
              <IconButton
                sx={iconButtonStyle}
                onClick={() => navigate("/events")}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </InnerBox>

          <InnerBox width="100%" maxWidth="25em">
            <h4>mijn groepen</h4>
            <IconButton sx={iconButtonStyle}>
              <AddIcon />
            </IconButton>
          </InnerBox>
          <InnerBox width="100%" padding="1em" maxWidth="25em">
            <h4>mijn evenementen</h4>
            {!(organizedEvents.length === 0) &&
              organizedEvents.map((event) => {
                let statusText;
                switch (event.status_type) {
                  case "REQUESTED":
                    statusText = "aangevraagd";
                    if (!eventRequested) {
                      setEventRequested(true);
                    }
                    break;
                  case "UNDER_REVIEW":
                    statusText = "wordt beoordeeld";
                    break;
                  case "ACCEPTED":
                    statusText = "aanvaard";
                    break;
                  case "DENIED":
                    statusText = "geweigerd";
                    break;
                  default:
                    statusText = "onbekend";
                }

                const startDate = event.setup_start_date
                  ? formatDate(event.setup_start_date)
                  : formatDate(event.event_start_date);
                const endDate = event.setup_start_date
                  ? formatDate(event.breakdown_end_date)
                  : formatDate(event.event_end_date);
                return (
                  <InnerBox width="90%" padding="1em">
                    <Box
                      sx={{
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1em",
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <h3>{event.name}</h3>
                        <Box
                          sx={{
                            padding: ".5em 1em",
                            fontWeight: "bold",
                            color: "White",
                            borderRadius: "5em",
                            cursor: "pointer",
                            backgroundColor: `var(--${event.status_type})`,
                          }}
                        >
                          {statusText}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "-1em",
                          textAlign: "justify",
                          width: "100%",
                        }}
                      >
                        <p>{event.description}</p>
                      </Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: ".5em",
                          width: "100%",
                          cursor: "pointer",
                          justifyContent: "flex-start",
                        }}
                      >
                        <PeopleIcon />{" "}
                        {event.capacity < 1000 ? `${event.capacity}` : `1000+`}
                        <RoomIcon />
                        {event.street
                          ? `${event.street} ${event.number}, ${event.postal_code} ${event.city}`
                          : `${event.postal_code} ${event.city}`}
                        <EventIcon />{" "}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            "& p": {
                              margin: "0",
                            },
                          }}
                        >
                          <p>{startDate}</p>
                          <p>{endDate}</p>
                        </Box>
                      </Box>
                      {event.status_type === "ACCEPTED" && (
                        <Box
                          sx={{
                            marginTop: "1em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1em",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          {event.is_managed ? (
                            <Button
                              onClick={() =>
                                navigate(`/manage/${event.urn}/posts`)
                              }
                              sx={{
                                width: "70%",
                                backgroundColor: "gray",
                                color: "white",
                              }}
                            >
                              beheren
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                navigate(`/manage/${event.urn}/setup`)
                              }
                              variant="contained"
                              sx={{
                                width: "70%",
                                backgroundColor: "var(--green)",
                                ":hover": {
                                  backgroundColor: "var(--green-hover)",
                                },
                              }}
                            >
                              starten
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  </InnerBox>
                );
              })}
            {!(managedEvents.length === 0) &&
              managedEvents.map((event) => {
                const startDate = event.setup_start_date
                  ? formatDate(event.setup_start_date)
                  : formatDate(event.event_start_date);
                const endDate = event.setup_start_date
                  ? formatDate(event.breakdown_end_date)
                  : formatDate(event.event_end_date);
                return (
                  <InnerBox width="90%" padding="1em">
                    <Box sx={{ textAlign: "left", width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1em",
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <h3>{event.name}</h3>
                        <Box
                          sx={{
                            padding: ".5em 1em",
                            fontWeight: "bold",
                            color: "White",
                            borderRadius: "5em",
                            backgroundColor: `var(--blue)`,
                          }}
                        >
                          manager
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "-1em",
                          textAlign: "justify",
                          width: "100%",
                        }}
                      >
                        <p>{event.description}</p>
                      </Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: ".5em",
                          width: "100%",
                          cursor: "pointer",
                          justifyContent: "flex-start",
                        }}
                      >
                        <PeopleIcon />{" "}
                        {event.capacity < 1000 ? `${event.capacity}` : `1000+`}
                        <RoomIcon />
                        {event.street
                          ? `${event.street} ${event.number}, ${event.postal_code} ${event.city}`
                          : `${event.postal_code} ${event.city}`}
                        <EventIcon />{" "}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            "& p": {
                              margin: "0",
                            },
                          }}
                        >
                          <p>{startDate}</p>
                          <p>{endDate}</p>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "1em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "1em",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <Button
                        onClick={() => navigate(`/manage/${event.urn}/posts`)}
                        sx={{
                          width: "70%",
                          backgroundColor: "gray",
                          color: "white",
                        }}
                      >
                        beheren
                      </Button>
                    </Box>
                  </InnerBox>
                );
              })}

            {!eventRequested && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/events/create"
                  style={{ width: "auto", textDecoration: "none" }}
                >
                  <IconButton sx={iconButtonStyle}>
                    <AddIcon />
                  </IconButton>
                </Link>
              </Box>
            )}
          </InnerBox>
        </Box>
      </Box>
    </PageContainer>
  );
}

export default DashboardPage;
