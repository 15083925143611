// keyUtils.js

/**
 * Transforms object keys from snake_case to camelCase recursively.
 * @param {Object} obj - The object to transform.
 * @returns {Object} - A new object with transformed keys.
 */
const transformKeys = (obj) => {
    if (!obj || typeof obj !== "object") return obj; // Handle non-objects gracefully
  
    return Object.entries(obj).reduce((acc, [key, value]) => {
      // Convert `snake_case` to `camelCase`
      const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  
      // Recursively transform nested objects
      acc[camelCaseKey] = transformKeys(value);
  
      return acc;
    }, Array.isArray(obj) ? [] : {});
  };
  
  export default transformKeys;
  