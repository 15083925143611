import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import apiClient from "../services/apiclient";
import PageContainer from "../components/PageContainer";
import { Box, Typography, Button, Snackbar, Alert, Fade } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
function EventDetailsPage() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(UserContext);
  const { eventURN } = useParams();
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventStart, setEventStart] = useState();
  const [eventEnd, setEventEnd] = useState();
  const [eventLocation, setEventLocation] = useState();
  const [loading, setLoading] = useState(true);
  const [eventNotFound, setEventNotFound] = useState(false);
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    const fetchData = () => {
      apiClient
        .get(`/events/public/${eventURN}`)
        .then((response) => {
          console.log("response:", response.data);
          setEventName(response.data.name);
          setEventDescription(response.data.description);
          if (response.data.street) {
            setEventLocation(
              `${response.data.street} ${response.data.number}, ${response.data.postal_code} ${response.data.city}`
            );
          } else {
            setEventLocation(
              response.data.postal_code + " " + response.data.city
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setEventNotFound(true);
          }
        });

      apiClient
        .get(`/events/public/${eventURN}/check-volunteer`)
        .then((response) => {
          setRegistered(true);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setRegistered(false);
          }
        });
      setLoading(false);
    };
    fetchData();
  }, []);
  console.log(isLoggedIn);
  return (
    <PageContainer>
      {!eventNotFound && !loading && (
        <Box
          sx={{
            maxWidth: "40rem",
            margin: "0 2rem",
            display: "flex",
            textAlign: "center",

            textSpacingTrim: "space-first",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            "& h1": {
              lineHeight: "2rem",
            },
          }}
        >
          <h1>{eventName}</h1>
          <Typography
            variant="p"
            sx={{
              textAlign: "justify",
            }}
          >
            {eventDescription}
          </Typography>
          <Box
            sx={{
              borderTop: "1px solid lightgray ",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 10fr",
              gap: ".5rem",
              padding: "1rem",
              justifyItems: "start",
              alignItems: "start",
            }}
          >
            <LocationOnIcon />
            <Typography>{eventLocation}</Typography>
          </Box>
          <Typography variant="p" sx={{
            fontWeight: "bold",
            color: "var(--black)",
          }}>
            U bent reeds geregistreerd voor dit evenement.
          </Typography>
          {registered ? (
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: "fit-content",
                }}
                onClick={() => navigate("register")}
              >
                inschrijving bijwerken
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "fit-content",
              }}
              onClick={
                isLoggedIn
                  ? () => navigate("register")
                  : () => navigate("/login")
              }
            >
              {isLoggedIn ? "inschrijven" : "inloggen om in te schrijven"}
            </Button>
          )}
        </Box>
      )}
      <Snackbar
        open={eventNotFound}
        autHideDuration={6000}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          Het event werd niet gevonden. Mogelijks staat dit evenement privé.
          Probeer een andere URL of neem contact op met de organisator.
        </Alert>
      </Snackbar>
    </PageContainer>
  );
}
export default EventDetailsPage;
