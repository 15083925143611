import React from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

function ConfirmationScreen({ message }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "15rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "2rem",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "3em",
          height: "3em",
          position: "relative",
          backgroundColor: "white",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          color: "#003de0ff",
          textAlign: "center",
          alignItems: "center",
        }}
        component={motion.div}
        animate={{
          scale: [2, 1, 1, 1, 2],
          rotate: [0, 90, -90, 360],
          borderRadius: ["20%", "50%", "50%", "50%", "50%"],
          y: [0, 0, 0, 0 , -30],
          backgroundColor: ["#ffffff", "#ffffff", "#ffffff", "#04724D"],
        }}
        transition={{
          duration: 2.5, // Duration of the animation cycle
          ease: "easeInOut", // Easing function
          repeat: 0, // Repeat infinitely
          repeatDelay: 0.5, // Pause for 0.5 seconds at the end of each cycle
        }}
      >
        <motion.h1
          transition={{
            duration: 2.5,
            ease: "easeInOut",
          }}
          animate={{
            opacity: [1, 1, 1, 0], // Fade out the V
          }}
        >
          V
        </motion.h1>

        {/* Checkmark icon with opacity transition */}
        <Box
          component={motion.div}
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ffffff",
            fontSize: "3rem",
            opacity: "0",
            width: "100%",
            height: "100%",
          }}
          initial={{ opacity: 0 }} // Start with opacity 0
          animate={{
            opacity: [0, 0, 0, 0, 1], // Fade in after V fades out
          }}
          transition={{
            duration: 2.5,
            ease: "easeInOut", // Easing function          }}
          }}
        >
          <CheckIcon htmlColor="white" fontSize="10rem" />
        </Box>
      </Box>
      <Box sx={{
        position: "absolute",
        bottom: 0,
        fontWeight: "bold",

      }}>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transform: "translateY(-10)",
          }}
          transition={{
            delay: 2,
            duration: .5,
          }}
        >
          {message}
        </motion.p>

      </Box>
    </Box>
  );
}

export default ConfirmationScreen;
